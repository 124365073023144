import React, { useEffect, useState } from "react";
import axios from "axios";
import WOW from "wow.js";
import get from "lodash/get";
import Loader from "react-loader-spinner";
import { isMobile } from "react-device-detect";

import ProjectCard from "./ProjectCard/";
import ProjectCategory from "./ProjectCategories/index";
import { formatProjectsList, sortProjectsByPriority } from "../helpers";

const wow = new WOW({
  boxClass: "wow", // default
  animateClass: "animated", // default
  offset: isMobile ? 50 : 100, // default
  mobile: true,
  live: true,
});

const ProjectsPage = ({
  setHeaderProperties,
  setMenuButtonColor,
  setMenuProperties,
  projectCategory,
  setSelectedProject,
}) => {
  const path = window.location.pathname;
  const urlCategory = path?.substring(path?.lastIndexOf("/") + 1);

  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [category, setCategory] = useState("all");
  const [isGettingProjects, setGettingProjects] = useState(false);
  document.title = "Paul Sangha Creative | Projects";

  useEffect(() => {
    setHeaderProperties("#000000", false);
    setMenuButtonColor("#000000");
    setMenuProperties("#000000");

    const getProjects = async () => {
      const data = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=project`
      );

      const projects = formatProjectsList(data);

      let category = (projectCategory
        ? projectCategory
        : urlCategory
      ).toLowerCase();

      const publishedProjects = projects.filter(
        ({ slug, status }) => slug !== "hidden" || status !== "published"
      );

      const nonArchivedProjects = publishedProjects.filter(
        (project) => !get(project, "project_type", []).includes("Archive")
      );

      //build object of categories with array of projects
      const categorizedProjects = publishedProjects.reduce(
        (agg, project) => {
          get(project, "project_type", []).forEach((category) => {
            const normalizedCategory = category.toLowerCase().trim();
            let categorizedProjects = agg[normalizedCategory] || [];
            agg[normalizedCategory] = [...categorizedProjects, project];
          });
          return agg;
        },
        //this object dictates project category button order
        { all: nonArchivedProjects }
      );

      const categorizedProjectsByPriority = sortProjectsByPriority(
        categorizedProjects
      );
      setProjects(categorizedProjectsByPriority);
      //If there are projects for the category selected, display otherwise use default
      if (
        categorizedProjects[category] &&
        categorizedProjects[category].length > 0
      ) {
        setCategory(category);
      }
      setLoading(false);
    };

    if (!isGettingProjects) {
      setGettingProjects(true);
      getProjects();
    }

    wow.init();
  }, [
    isGettingProjects,
    projectCategory,
    setHeaderProperties,
    setMenuProperties,
    setMenuButtonColor,
    urlCategory,
  ]);

  useEffect(() => {
    if (urlCategory === "projects") {
      return setCategory("all");
    }

    if (urlCategory !== category) {
      return setCategory(urlCategory);
    }
    setCategory(category);
  }, [urlCategory, category]);

  const handleCategoryChange = (selectedCat) => {
    if (selectedCat !== category) {
      setCategory(selectedCat);
      window.history.pushState(
        selectedCat,
        "Projects",
        `/projects/${selectedCat}`
      );
      window.scrollTo(0, 0);
    }
  };

  if (isLoading || projects.length === 0 || !projects[category]) {
    return (
      <div className='loader-container'>
        <Loader type='Oval' color='#000000' height={40} width={40} />
      </div>
    );
  }

  const selectedProjects = projects[category];
  const projectMidpoint = selectedProjects.length / 2;
  //Maintain priority in mobile view
  const column1 = selectedProjects.filter((_, i) =>
    isMobile ? i < projectMidpoint : i % 2 === 0
  );
  const column2 = selectedProjects.filter((_, i) =>
    isMobile ? i >= projectMidpoint : i % 2 !== 0
  );

  //Put Archive at the end of the array
  const categories = Object.keys(projects).filter((category) => {
    //Display All option on mobile
    const categoriesToRemove = isMobile ? ["archive"] : ["archive", "all"];
    return !categoriesToRemove.includes(category);
  });
  categories.push("archive");

  return (
    <div id='project-list' className='project-list-page'>
      <ProjectCategory
        categories={categories}
        handleChange={handleCategoryChange}
        projects={projects}
        category={category}
      />
      <div className='content'>
        <div className='grid-container-projects'>
          <div className='column-left'>
            {column1.map((post, index) => (
              <ProjectCard
                project={post}
                key={index}
                index={index}
                column='left'
                setSelectedProject={setSelectedProject}
              />
            ))}
          </div>
          <div className='column-right'>
            {column2.map((post, index) => (
              <ProjectCard
                project={post}
                key={index}
                index={index}
                column='right'
                setSelectedProject={setSelectedProject}
              />
            ))}
          </div>
        </div>
      </div>
      <span className='footer-cp'>
        Copyright &copy; 2021. All rights reserved.
      </span>
    </div>
  );
};

export default ProjectsPage;
