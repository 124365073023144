import React, { Component } from "react";

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className='contact-info-container'>
        <div className='address-container'>
          <span className='address-header'>ADDRESS</span>
          <br />
          <span className='street-info'>336 West 5th Avenue</span>
          <br />
          <span className='city-info'>Vancouver BC V5Y 1J5</span>
        </div>
        <div className='phone-email-container'>
          <span className='phone-email-header'>PHONE / EMAIL</span>
          <br />
          <span className='phone-number'>604 736 2323</span>
          <br />
          <span className='email-address'>
            <a style={{ color: "#000000" }} href='mailto:info@paulsangha.com'>
              info@paulsangha.com
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default Address;
