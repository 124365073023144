import React, { useState } from "react";

const ALL_PROJECTS = "ALL PROJECTS";

const differentDisplayText = {
  all: ALL_PROJECTS,
};

const Dropdown = ({ items, handleChange, currentItem }) => {
  const [isOpen, setOpen] = useState(false);
  const [displayText, setText] = useState(currentItem);

  const handleItemClick = (item) => {
    setText(item);
    handleChange(item);
  };

  const itemList = () => {
    const list = items.map((item) => (
      <div
        onClick={() => handleItemClick(item)}
        className='dropdown__item'
        key={item}
      >
        {differentDisplayText[item] ? differentDisplayText[item] : item}
      </div>
    ));

    return <div className='dropdown__items'> {list} </div>;
  };

  return (
    <div
      className={isOpen ? "dropdown active" : "dropdown"}
      onClick={() => setOpen(!isOpen)}
    >
      <div className='dropdown__text'>
        {differentDisplayText[currentItem]
          ? differentDisplayText[currentItem]
          : displayText}
      </div>
      {itemList(items)}
    </div>
  );
};

export default Dropdown;
