import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  render() {
    const mapStyles = [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#444444",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#f2f2f2",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "57",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.stroke",
        stylers: [
          {
            lightness: "1",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            visibility: "off",
          },
          {
            color: "#484848",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.station.bus",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.station.bus",
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: "0",
          },
          {
            lightness: "0",
          },
          {
            gamma: "1.00",
          },
          {
            weight: "1",
          },
        ],
      },
      {
        featureType: "transit.station.bus",
        elementType: "labels.icon",
        stylers: [
          {
            saturation: "-100",
          },
          {
            weight: "1",
          },
          {
            lightness: "0",
          },
        ],
      },
      {
        featureType: "transit.station.rail",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.station.rail",
        elementType: "labels.text.fill",
        stylers: [
          {
            gamma: "1",
          },
          {
            lightness: "40",
          },
        ],
      },
      {
        featureType: "transit.station.rail",
        elementType: "labels.icon",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "30",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#d2d2d2",
          },
          {
            visibility: "on",
          },
        ],
      },
    ];
    var style = {
      width: "100%",
      height: "450px",
      position: "relative",
    };
    return (
      <div style={style}>
        <Map
          google={this.props.google}
          zoom={16}
          initialCenter={{
            lat: 49.2666056,
            lng: -123.1117255,
          }}
          styles={mapStyles}
        >
          <Marker
            onClick={this.onMarkerClick}
            name={"Paul Sangha Creative"}
            icon={{
              url:
                "https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_black.png",
            }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyArfszmDd7Do5uXhLPv5-d8SYxuT6Hc4qU",
})(MapContainer);
